var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'ni ni-chart-bar-32',
        path: '/dashboard'
      }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Clients', icon: 'fas fa-users', path: '/clients' }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Sessions',
        icon: 'fas fa-user-clock',
        path: '/sessions'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Settings',
        icon: 'fas fa-cog',
        path: '/account/settings'
      }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Helpful Links")]),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('b-nav-item',{attrs:{"to":"/help","target":"_blank"}},[_c('i',{staticClass:"ni ni-support-16"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Contact Us")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }