import { authGuard } from '@/auth/authGuard'

import Dashboard from '@/views/Layout/Dashboard.vue'
import AuthLayout from '@/views/Layout/AuthLayout.vue'
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: Dashboard,
    redirect: '/dashboard',
    name: 'Provider Portal',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Pages/Dashboard.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/clients',
        name: 'Clients',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Pages/Clients.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/timeline',
        name: 'Timeline',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Pages/TimeLine.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/clients/:id',
        name: 'Client Profile',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Pages/ClientProfile.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/sessions',
        name: 'Sessions',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Pages/Sessions.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/help',
        name: 'Help',
        beforeEnter() {
          window.location.href = 'https://qmxzzyqrq2i.typeform.com/to/kH0Y6rSR'
        }
      },
      {
        path: '/account/settings',
        name: 'Settings',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/Account/Settings.vue'
          ),
        beforeEnter: authGuard
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue'),
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/terms',
        name: 'Terms of Service',
        component: () =>
          import(/* webpackChunkName: "pages" */ '@/views/Pages/Terms.vue'),
        meta: {}
      },
      {
        path: '/payment/clientcard/:client',
        name: 'Add Payment Method',
        component: () =>
          import(
            /* webpackChunkName: "pages" */ '@/views/Pages/PaymentClientCard.vue'
          )
      },
      {
        path: '/payment/success/:session',
        name: 'Payment Method Saved',
        component: () =>
          import(
            /* webpackChunkName: "pages" */ '@/views/Pages/PaymentSuccess.vue'
          )
      },
      {
        path: '/pricing',
        name: 'Pricing',
        component: () =>
          import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue')
      },
      {
        path: '/account/email-verification',
        name: 'Email Verifcation',
        component: () =>
          import(
            /* webpackChunkName: "pages" */ '@/views/Account/EmailVerification.vue'
          )
      },
      {
        path: '/cancel-session',
        name: 'Cancel Session',
        component: () =>
          import(
            /* webpackChunkName: "pages" */ '@/views/Pages/CancelSession.vue'
          )
      },
      { path: '*', component: NotFound }
    ]
  }
]

export default routes
