import Vue from 'vue'
import apiService from '@/services/apiService'

const SESSIONGROUP_ENDPOINT = 'sessiongroup'
const MIN_FETCH_INTERVAL = 5000

const sessionGroupsModule = {
  namespaced: true,
  state: () => ({
    loading: false,
    ready: false,
    sessiongroupDetails: {},
    sessiongroups: []
  }),
  getters: {
    inactiveSessionGroups(state) {
      return state.sessiongroups
        .filter(sessiongroup => !sessiongroup.repeat_options?.active)
        .sort((x, y) => (x.created < y.created ? -1 : 1))
    },
    activedSessionGroups(state) {
      return state.sessiongroups
        .filter(sessiongroup => sessiongroup.repeat_options?.active === true)
        .sort((x, y) => (x.created < y.created ? -1 : 1))
    }
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setReady(state, ready) {
      state.ready = ready
    },
    setSessionGroups(state, sessiongroups) {
      const sessiongroupDetails = {}
      sessiongroups.forEach(sessiongroup => {
        sessiongroupDetails[sessiongroup.sessiongroup] = sessiongroup
      })
      state.sessiongroups = sessiongroups
      state.sessiongroupDetails = sessiongroupDetails
    },
    addSessionGroup(state, sessiongroup) {
      state.sessiongroups.push(sessiongroup)
      Vue.set(
        state.sessiongroupDetails,
        sessiongroup.sessiongroup,
        sessiongroup
      )
    },
    updateSessionGroup(state, { id, sessiongroup }) {
      const sessionGroupIndex = state.sessiongroups.findIndex(
        sessiongroup => sessiongroup.sessiongroup === id
      )
      Vue.set(state.sessiongroups, sessionGroupIndex, sessiongroup)
      Vue.set(state.sessiongroupDetails, id, sessiongroup)
    },
    deleteSessionGroup(state, id) {
      const sessionGroupIndex = state.sessiongroups.findIndex(
        sessiongroup => sessiongroup.sessiongroup === id
      )
      Vue.delete(state.sessiongroups, sessionGroupIndex)
      Vue.delete(state.sessiongroupDetails, id)
    }
  },
  actions: {
    async fetch({ commit, state }) {
      if (!state.loading || Date.now() - state.loading > MIN_FETCH_INTERVAL) {
        commit('setLoading', Date.now())
        commit(
          'setSessionGroups',
          await apiService.listEndpoint(SESSIONGROUP_ENDPOINT)
        )
        commit('setReady', true)
      }
    },
    async fetchSessionGroupDetails({ commit }, id) {
      const sessiongroup = await apiService.getEndpoint(
        SESSIONGROUP_ENDPOINT,
        id
      )
      commit('updateSessionGroup', {
        id: sessiongroup.sessiongroup,
        sessiongroup
      })
      return sessiongroup
    },
    async save({ commit, state }, sessionGroupToSave) {
      const found = state.sessiongroupDetails[sessionGroupToSave.sessiongroup]
      let resp
      if (found) {
        // existing
        resp = await apiService.editEndpoint(
          SESSIONGROUP_ENDPOINT,
          found.sessiongroup,
          apiService.sanitizeFields(sessionGroupToSave, SESSIONGROUP_ENDPOINT)
        )
        commit('updateSessionGroup', {
          id: sessionGroupToSave.sessiongroup,
          sessiongroup: { ...found, ...sessionGroupToSave }
        })
        return sessionGroupToSave.sessiongroup
      } else {
        resp = await apiService.addEndpoint(
          SESSIONGROUP_ENDPOINT,
          apiService.sanitizeFields(sessionGroupToSave, SESSIONGROUP_ENDPOINT)
        )
        commit('addSessionGroup', {
          ...sessionGroupToSave,
          ...{ sessiongroup: resp.data.sessiongroup }
        })
        return resp.data.sessiongroup
      }
    },
    async updateActive({ commit, state }, { id, active }) {
      const found = state.sessiongroups.find(
        sessiongroup => sessiongroup.sessiongroup === id
      )
      if (found) {
        const updated = {
          ...found,
          repeat_options: {
            ...found.repeat_options,
            active: active
          }
        }
        await apiService.editEndpoint(
          SESSIONGROUP_ENDPOINT,
          id,
          apiService.sanitizeFields(updated, SESSIONGROUP_ENDPOINT)
        )
        commit('updateSessionGroup', {
          id: updated.sessiongroup,
          sessiongroup: updated
        })
      }
    },
    async delete({ commit }, id) {
      await apiService.deleteEndpoint(SESSIONGROUP_ENDPOINT, id)
      commit('deleteSessionGroup', id)
    }
  }
}

export default sessionGroupsModule
