import apiService from '@/services/apiService'

const MIN_FETCH_INTERVAL = 5000

const providerModule = {
  namespaced: true,
  state: () => ({
    loading: null,
    ready: false,
    provider: {}
  }),
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setReady(state, ready) {
      state.ready = ready
    },
    setProvider(state, provider) {
      state.provider = provider
    }
  },
  actions: {
    async fetch({ commit, state }) {
      if (!state.loading || Date.now() - state.loading > MIN_FETCH_INTERVAL) {
        commit('setLoading', Date.now())
        commit('setProvider', await apiService.getProviderProfile())
        commit('setReady', true)
      }
    },
    async save({ commit }, providerToSave) {
      delete providerToSave.role
      delete providerToSave.auth_id
      await apiService.editProviderProfile(
        apiService.sanitizeFields(providerToSave)
      )
      commit('setProvider', providerToSave)
    }
  }
}

export default providerModule
