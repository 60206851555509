import providerModule from './modules/provider'
import clientsModule from './modules/clients'
import sessionsModule from './modules/sessions'
import sessionGroupsModule from './modules/sessiongroups'
import tasksModule from './modules/tasks'

function registerModules(store) {
  store.registerModule('provider', providerModule)
  store.registerModule('clients', clientsModule)
  store.registerModule('sessions', sessionsModule)
  store.registerModule('sessiongroups', sessionGroupsModule)
  store.registerModule('tasks', tasksModule)
}

export { registerModules }
