import axios from 'axios'
import { getInstance } from '@/auth/index'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  validateStatus: function(status) {
    return (status >= 200 && status < 300) || status === 400 // allow 400
  }
})

const apiCall = async (method, endpoint, data) => {
  try {
    let options = {}

    if (
      !~endpoint.indexOf('/payment/success/') &&
      !~endpoint.indexOf('/misc/cancel-session')
    ) {
      // only auth'd endpoints
      const token = await getInstance().getTokenSilently()
      if (token) {
        options = {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      }
    }

    console.log(`APIService ${method} ${endpoint}`)

    let result
    if (method === 'get') result = await apiClient.get(endpoint, options)
    else if (method === 'post')
      result = await apiClient.post(endpoint, data, options)
    else if (method === 'put')
      result = await apiClient.put(endpoint, data, options)
    else if (method === 'delete')
      result = await apiClient.delete(endpoint, options)

    if (result.data.status !== 'ok') {
      console.log(result.data)
      throw new Error(result.data.message)
    }
    return result.data
  } catch (e) {
    console.log('APIService error ' + endpoint)
    console.log(e)
    if (
      e &&
      e.message === 'Login required' &&
      !~endpoint.indexOf('/payment/success/')
    ) {
      // eslint-disable-next-line no-self-assign
      window.location = window.location
    }
    throw e
  }
}

export default {
  apiCall
}
