import Vue from 'vue'
import Vuex from 'vuex'
import DashboardPlugin from './plugins/dashboard-plugin'
import App from './App.vue'

import router from './routes/router'
import { registerModules } from './store'

Vue.use(Vuex)

const store = new Vuex.Store({})
registerModules(store)

// init auth0
import { Auth0Plugin } from './auth'
const domain = process.env.VUE_APP_AUTH0_DOMAIN
const clientId = process.env.VUE_APP_AUTH0_CLIENTID
const audience = process.env.VUE_APP_AUTH0_AUDIENCE

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

// plugin setup
Vue.use(DashboardPlugin)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router
})
