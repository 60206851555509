import Vue from 'vue'
import apiService from '@/services/apiService'

const TASK_ENDPOINT = 'task'
const MIN_FETCH_INTERVAL = 5000

const tasksModule = {
  namespaced: true,
  state: () => ({
    loading: false,
    ready: false,
    tasks: []
  }),
  getters: {
    incompleteTasks(state) {
      return state.tasks
        .filter(task => !task.done || task.marked_done)
        .sort((x, y) => (x.created < y.created ? -1 : 1))
    },
    completedTasks(state) {
      return state.tasks
        .filter(task => task.done === true)
        .sort((x, y) => (x.created < y.created ? -1 : 1))
    },
    allTasks(state) {
      return state.tasks.sort((x, y) => (x.created < y.created ? -1 : 1))
    }
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setReady(state, ready) {
      state.ready = ready
    },
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    addTask(state, task) {
      state.tasks.push(task)
    },
    updateTask(state, { id, task }) {
      const taskIndex = state.tasks.findIndex(task => task.task === id)
      Vue.set(state.tasks, taskIndex, task)
    },
    deleteTask(state, id) {
      const taskIndex = state.tasks.findIndex(task => task.task === id)
      Vue.delete(state.tasks, taskIndex)
    }
  },
  actions: {
    async fetch({ commit, state }) {
      if (!state.loading || Date.now() - state.loading > MIN_FETCH_INTERVAL) {
        commit('setLoading', Date.now())
        commit('setTasks', await apiService.listEndpoint(TASK_ENDPOINT))
        commit('setReady', true)
      }
    },
    async fetchTaskDetails({ commit }, id) {
      const task = await apiService.getEndpoint(TASK_ENDPOINT, id)
      commit('updateTask', { id: task.task, task })
    },
    async save({ commit, state }, taskToSave) {
      const found = state.tasks.find(task => task.task === taskToSave.task)
      let resp
      if (found) {
        // existing
        resp = await apiService.editEndpoint(
          TASK_ENDPOINT,
          found.task,
          apiService.sanitizeFields(taskToSave, TASK_ENDPOINT)
        )
        commit('updateTask', {
          id: taskToSave.task,
          task: { ...found, ...taskToSave }
        })
      } else {
        resp = await apiService.addEndpoint(
          TASK_ENDPOINT,
          apiService.sanitizeFields(taskToSave, TASK_ENDPOINT)
        )
        commit('addTask', {
          ...taskToSave,
          ...{ task: resp.data.task }
        })
      }
    },
    async updateDone({ commit, state }, { id, done }) {
      const found = state.tasks.find(task => task.task === id)
      if (found) {
        const updated = {
          ...found,
          done: done
        }
        await apiService.editEndpoint(
          TASK_ENDPOINT,
          id,
          apiService.sanitizeFields(
            {
              ...updated,
              marked_done: undefined // allow checkbox functionality on client profile
            },
            TASK_ENDPOINT
          )
        )
        commit('updateTask', {
          id: updated.task,
          task: updated
        })
      }
    },
    async delete({ commit }, id) {
      await apiService.deleteEndpoint(TASK_ENDPOINT, id)
      commit('deleteTask', id)
    }
  }
}

export default tasksModule
