<template>
  <div>
    <notifications />
    <base-nav
      v-model="showMenu"
      type="light"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main"
      expand="lg"
    >
      <div slot="brand" class="navbar-wrapper">
        <b-navbar-brand to="/">
          <img src="/img/brand/white.png" />
        </b-navbar-brand>
      </div>
      <div class="navbar-collapse-header">
        <b-row>
          <b-col cols="6" class="collapse-brand">
            <router-link to="/">
              <img src="/img/brand/logo.png" />
            </router-link>
          </b-col>
          <b-col cols="6" class="collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </b-col>
        </b-row>
      </div>

      <b-navbar-nav class="mr-auto">
        <!-- <b-nav-item to="/features">
          <span class="nav-link-inner--text">Features</span>
        </b-nav-item>
        <b-nav-item to="/pricing">
          <span class="nav-link-inner--text">Pricing</span>
        </b-nav-item> -->
        <b-nav-item href="https://qmxzzyqrq2i.typeform.com/to/kH0Y6rSR">
          <span class="nav-link-inner--text">Contact Us</span>
        </b-nav-item>
      </b-navbar-nav>
      <template v-if="enabled">
        <hr class="d-lg-none" />
        <b-navbar-nav class="align-items-lg-center ml-lg-auto">
          <b-nav-item v-if="!$auth.isAuthenticated" @click="providerLogin">
            <span class="nav-link-inner--text">Provider Login</span>
          </b-nav-item>
          <li class="nav-item d-none d-lg-block ml-lg-4" v-if="!$auth.loading">
            <span
              v-if="!$auth.isAuthenticated"
              @click="providerSignup"
              class="btn btn-neutral btn-icon"
            >
              <span class="btn-inner--icon">
                <i class="ni ni-single-02 mr-2"></i>
              </span>
              <span class="nav-link-inner--text">Sign Up</span>
            </span>
          </li>
        </b-navbar-nav>
        <navbar-user />
      </template>
    </base-nav>

    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-5" id="footer-main">
      <b-container>
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="6">
            <div class="copyright text-center text-xl-left text-muted">
              © {{ year }} Zenclear
            </div>
          </b-col>
          <b-col xl="6" class="col-xl-6">
            <b-nav
              class="nav-footer justify-content-center justify-content-xl-end"
            >
              <b-nav-item to="/help" target="_blank">
                Help
              </b-nav-item>
              <b-nav-item to="/terms">
                Terms &amp; Privacy
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions'
import NavbarUser from './NavbarUser.vue'

export default {
  components: {
    ZoomCenterTransition,
    NavbarUser
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
      enabled: process.env.VUE_APP_ENABLE_LOGIN === '1'
    }
  },
  computed: {
    title() {
      return `${this.$route.name} Page`
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    },
    setBackgroundColor() {
      document.body.classList.add('bg-default')
    },
    removeBackgroundColor() {
      document.body.classList.remove('bg-default')
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor()
      } else {
        this.removeBackgroundColor()
      }
    },
    providerLogin() {
      this.$auth.loginWithRedirect({ appState: { targetUrl: '/dashboard' } })
    },
    providerSignup() {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: '/dashboard' },
        screen_hint: 'signUp'
      })
    }
  },
  beforeDestroy() {
    this.removeBackgroundColor()
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu()
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function() {
        this.updateBackground()
      }
    }
  }
}
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.beige-text {
  background-color: #fdf8e8;

  &:focus {
    background-color: #fdf8e8;
  }
}

.nav-pills {
  .nav-link {
    background-color: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
    color: gray;
    font-size: 1.0625rem;
    font-weight: 600;
    margin: 20px 0 10px 20px;
    padding: 0 5px 10px 3px;
  }
  .nav-link.active,
  .show > .nav-link {
    background-color: transparent;
    border-bottom: 2px solid #6669c5;
    border-radius: 0;
    color: #32325d;
  }
}
</style>
