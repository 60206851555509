<template>
  <b-navbar-nav class="align-items-center ml-auto ml-md-0">
    <base-dropdown
      menu-on-right
      class="nav-item"
      tag="li"
      title-tag="a"
      title-classes="nav-link pr-0"
      v-if="$auth.isAuthenticated"
    >
      <a
        v-if="authedUser"
        href="#"
        class="nav-link pr-0"
        @click.prevent
        slot="title-container"
      >
        <b-media no-body class="align-items-center">
          <span
            style="background: transparent"
            class="avatar avatar-sm rounded-circle"
          >
            <i v-if="!$auth.user.picture" class="ni ni-single-02 mb-1"></i>
            <img v-else :src="$auth.user.picture" />
          </span>
          <b-media-body class="ml-2 d-none d-lg-block">
            <span class="mb-0 text-sm font-weight-bold">
              {{
                this.providerDetails.first_name ||
                  this.$auth.user.data.first_name
              }}
              {{
                this.providerDetails.last_name || this.$auth.user.data.last_name
              }}
            </span>
          </b-media-body>
        </b-media>
      </a>

      <template>
        <b-dropdown-header class="noti-title">
          <h6 v-if="authedUser" class="text-overflow m-0">
            Welcome{{
              this.providerDetails.first_name || this.$auth.user.data.first_name
                ? ' ' +
                  (this.providerDetails.first_name ||
                    this.$auth.user.data.first_name)
                : ''
            }}!
          </h6>
        </b-dropdown-header>
        <b-dropdown-item to="/dashboard">
          <i class="ni ni-chart-bar-32"></i>
          <span>Dashboard</span>
        </b-dropdown-item>
        <div class="dropdown-divider"></div>
        <b-dropdown-item to="/account/settings">
          <i class="fas fa-cog"></i>
          <span>Settings</span>
        </b-dropdown-item>
        <b-dropdown-item href="/help" target="_blank">
          <i class="ni ni-support-16"></i>
          <span>Help</span>
        </b-dropdown-item>
        <div class="dropdown-divider"></div>
        <b-dropdown-item @click="providerLogout">
          <i class="fas fa-sign-out-alt"></i>
          <span>Logout</span>
        </b-dropdown-item>
      </template>
    </base-dropdown>
  </b-navbar-nav>
</template>

<script>
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  mixins: [providerDetailsMixin],
  computed: {
    authedUser() {
      return this.$auth.dataLoaded && this.$auth.user
    }
  },
  methods: {
    providerLogout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
