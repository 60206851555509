import Vue from 'vue'
import apiService from '@/services/apiService'
import { isDiff } from '@/util/diff'

const SESSION_ENDPOINT = 'session'
const NO_CHARGE = 'no-charge'
const MIN_FETCH_INTERVAL = 5000

const sessionsModule = {
  namespaced: true,
  state: () => ({
    loading: null,
    ready: false,
    fixedData: false,
    sessions: []
  }),
  getters: {
    activeSessions(state) {
      return state.sessions
        .filter(session => session.status === 'scheduled')
        .sort((x, y) => (x.start_date_time < y.start_date_time ? -1 : 1))
    },
    doneSessions(state) {
      return state.sessions
        .filter(
          session =>
            session.status === 'completed' ||
            session.status === 'missed' ||
            session.status === 'canceled'
        )
        .sort((x, y) => (x.start_date_time < y.start_date_time ? -1 : 1))
    },
    unpaidSessions(state) {
      return state.sessions
        .filter(
          session =>
            (session.payment_status === 'unpaid' ||
              session.payment_status === 'pending') &&
            (new Date(session.end_date_time).getTime() < Date.now() ||
              session.status === 'completed' ||
              session.status === 'missed' ||
              session.status === 'canceled')
        )
        .sort((x, y) => (x.start_date_time < y.start_date_time ? -1 : 1))
    }
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setReady(state, ready) {
      state.ready = ready
    },
    setFixedData(state, fixedData) {
      state.fixedData = fixedData
    },
    setSessions(state, sessions) {
      state.sessions = sessions
    },
    addSession(state, session) {
      state.sessions.push(session)
    },
    updateSession(state, { id, session }) {
      const sessionIndex = state.sessions.findIndex(
        session => session.session === id
      )
      Vue.set(state.sessions, sessionIndex, session)
    },
    deleteSession(state, id) {
      const sessionIndex = state.sessions.findIndex(
        session => session.session === id
      )
      Vue.delete(state.sessions, sessionIndex)
    }
  },
  actions: {
    async fetch({ commit, state, dispatch }) {
      if (!state.loading || Date.now() - state.loading > MIN_FETCH_INTERVAL) {
        commit('setLoading', Date.now())
        commit('setSessions', await apiService.listEndpoint(SESSION_ENDPOINT))
        commit('setReady', true)
      }
      if (!state.fixedData) {
        dispatch('fixData')
      }
    },
    async fetchSessionDetails({ commit }, id) {
      const session = await apiService.getEndpoint(SESSION_ENDPOINT, id)
      commit('updateSession', { id: session.session, session })
    },
    async save({ commit, state }, sessionToSave) {
      const found = state.sessions.find(
        session => session.session === sessionToSave.session
      )
      let resp
      if (found) {
        // existing
        resp = await apiService.editEndpoint(
          SESSION_ENDPOINT,
          found.session,
          apiService.sanitizeFields(sessionToSave, SESSION_ENDPOINT)
        )
        commit('updateSession', {
          id: sessionToSave.session,
          session: { ...found, ...sessionToSave }
        })
        return sessionToSave.session
      } else {
        resp = await apiService.addEndpoint(
          SESSION_ENDPOINT,
          apiService.sanitizeFields(sessionToSave, SESSION_ENDPOINT)
        )
        commit('addSession', {
          ...sessionToSave,
          ...{ session: resp.data.session }
        })
        return resp.data.session
      }
    },
    async updatePaymentStatus({ commit, state }, { id, paymentStatus }) {
      const found = state.sessions.find(session => session.session === id)
      if (found) {
        const updated = {
          ...found,
          payment_status: paymentStatus
        }
        await apiService.editEndpoint(
          SESSION_ENDPOINT,
          id,
          apiService.sanitizeFields(updated, SESSION_ENDPOINT)
        )
        commit('updateSession', {
          id: updated.session,
          session: updated
        })
      }
    },
    async updateStatus({ commit, state }, { id, status, noCharge }) {
      const found = state.sessions.find(session => session.session === id)
      if (found) {
        const updated = {
          ...found,
          status,
          payment_status: noCharge ? NO_CHARGE : found.payment_status
        }
        await apiService.editEndpoint(
          SESSION_ENDPOINT,
          id,
          apiService.sanitizeFields(updated, SESSION_ENDPOINT)
        )
        commit('updateSession', {
          id: updated.session,
          session: updated
        })
      }
    },
    async chargeSession({ commit, state }, { id }) {
      const found = state.sessions.find(session => session.session === id)
      if (found) {
        const resp = await apiService.chargeSession(id)
        console.log(resp)
        if (resp.data) {
          // merely updates state in vuex, actual db update happens in backend route
          const updated = {
            ...found,
            payment_status: resp.data.payment_status
          }
          if (
            resp.message === 'succeeded' &&
            resp.data.id &&
            resp.data.status
          ) {
            updated.meta = updated.meta || {}
            updated.meta.stripe_payment_intent = resp.data.id
            updated.meta.stripe_payment_status = resp.data.status
            updated.meta.stripe_payment_method_brand = resp.data.brand
            updated.meta.stripe_payment_method_last4 = resp.data.last4
          }
          commit('updateSession', {
            id: updated.session,
            session: updated
          })
        }
      }
    },
    async updateClientDetails(
      { state, dispatch },
      { clientId, clientDetails }
    ) {
      await dispatch('fetch')
      state.sessions
        .filter(
          session =>
            session.client === clientId && session.status === 'scheduled'
        )
        .forEach(session => {
          let changed = false

          // only update if details is different
          if (
            isDiff(session.client_details, clientDetails, [
              'name',
              'email',
              'phone'
            ])
          ) {
            changed = true
            session.client_details = clientDetails
          }

          // commit
          if (changed) {
            dispatch('save', session)
          }
        })
    },
    async fixData({ commit, state, dispatch }) {
      await this.dispatch('clients/fetch')
      await this.dispatch('provider/fetch')
      if (
        this.state.provider.provider.email &&
        this.state.clients.clientDetails.length
      ) {
        commit('setFixedData', true)
      }

      state.sessions.forEach(session => {
        let changed = false

        // fill in denormalized info
        if (!session.client_details) {
          const clientDetails = this.state.clients.clientDetails[session.client]
          if (clientDetails) {
            changed = true
            session.client_details = {
              name: clientDetails.first_name + ' ' + clientDetails.last_name,
              email: clientDetails.email,
              phone: clientDetails.phone || undefined
            }
          }
        }

        // fill in denormalized info
        if (!session.provider_details) {
          const providerDetails = this.state.provider.provider
          if (providerDetails.email) {
            changed = true
            session.provider_details = {
              name:
                providerDetails.business_name ||
                providerDetails.first_name + ' ' + providerDetails.last_name,
              email: providerDetails.business_email || providerDetails.email,
              phone:
                providerDetails.business_phone ||
                providerDetails.phone ||
                undefined,
              timezone: providerDetails.timezone
            }
          }
        }

        // fill in denormalized info
        if (!session.rate_name) {
          const providerDetails = this.state.provider.provider
          if (providerDetails?.rates) {
            session.rate_name = providerDetails.rates.find(
              x => x.rate === session.rate
            )?.rate_name
            if (session.rate_name) {
              changed = true
            }
          }
        }

        // fill in missing info
        if (!session.scheduled_date_time) {
          changed = true
          session.scheduled_date_time = session.created
        }

        // commit
        if (changed) {
          dispatch('save', session)
        }
      })
    },
    async delete({ commit }, id) {
      await apiService.deleteEndpoint(SESSION_ENDPOINT, id)
      commit('deleteSession', id)
    }
  }
}

export default sessionsModule
