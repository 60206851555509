import Vue from 'vue'
import phoneFormatter from 'phone-formatter'

export default {
  computed: {
    providerReady() {
      return this.$store.state.provider.ready
    },
    providerDetails() {
      return this.$store.state.provider.provider || {}
    },
    providerName() {
      return this.providerDetails
        ? `${this.providerDetails.first_name || ''} ${this.providerDetails
            .last_name || ''}`
        : ''
    },
    providerEmail() {
      return this.providerDetails.email
    },
    providerBusinessEmail() {
      return this.providerDetails.business_email
    },
    providerPhone() {
      return this.providerDetails.phone &&
        this.providerDetails.phone.length === 10
        ? phoneFormatter.format(this.providerDetails.phone, '(NNN) NNN-NNNN')
        : this.providerDetails.phone
    },
    providerBusinessPhone() {
      return this.providerDetails.business_phone &&
        this.providerDetails.business_phone.length === 10
        ? phoneFormatter.format(
            this.providerDetails.business_phone,
            '(NNN) NNN-NNNN'
          )
        : this.providerDetails.business_phone
    },
    providerLocation() {
      if (this.providerDetails.location?.location)
        return this.providerDetails.location?.location
      else
        return (
          (this.providerDetails.location?.address
            ? this.providerDetails.location?.address + '\n'
            : '') +
          ((this.providerDetails.location?.city &&
            this.providerDetails.location?.state &&
            this.providerDetails.location?.city +
              ', ' +
              this.providerDetails.location?.state) ||
            '')
        )
    },
    providerBusinessLocation() {
      if (this.providerDetails.business_location?.location)
        return this.providerDetails.business_location?.location
      else
        return (
          (this.providerDetails.business_location?.address
            ? this.providerDetails.business_location?.address + '\n'
            : '') +
          ((this.providerDetails.business_location?.city &&
            this.providerDetails.business_location?.state &&
            this.providerDetails.business_location?.city +
              ', ' +
              this.providerDetails.business_location?.state) ||
            '')
        )
    },
    providerLocationWithAddress2() {
      if (this.providerDetails.location?.location)
        return this.providerDetails.location?.location
      else
        return (
          (this.providerDetails.location?.address
            ? this.providerDetails.location?.address + '\n'
            : '') +
          (this.providerDetails.location?.address2
            ? this.providerDetails.location?.address2 + '\n'
            : '') +
          ((this.providerDetails.location?.city &&
            this.providerDetails.location?.state &&
            this.providerDetails.location?.city +
              ', ' +
              this.providerDetails.location?.state +
              ' ' +
              this.providerDetails.location?.zip) ||
            '')
        )
    },
    providerBusinessLocationWithAddress2() {
      if (this.providerDetails.business_location?.location)
        return this.providerDetails.business_location?.location
      else
        return (
          (this.providerDetails.business_location?.address
            ? this.providerDetails.business_location?.address + '\n'
            : '') +
          (this.providerDetails.business_location?.address2
            ? this.providerDetails.business_location?.address2 + '\n'
            : '') +
          ((this.providerDetails.business_location?.city &&
            this.providerDetails.business_location?.state &&
            this.providerDetails.business_location?.city +
              ', ' +
              this.providerDetails.business_location?.state +
              ' ' +
              this.providerDetails.business_location?.zip) ||
            '')
        )
    },
    providerAddress2: {
      get() {
        if (this.providerDetails.location?.address2)
          return this.providerDetails.location?.address2
      },
      set(address2) {
        if (!this.providerDetails.location) {
          this.providerDetails.location = {}
        }
        Vue.set(this.providerDetails.location, 'address2', address2)
      }
    },
    providerBusinessAddress2: {
      get() {
        if (this.providerDetails.business_location?.address2)
          return this.providerDetails.business_location?.address2
      },
      set(address2) {
        if (!this.providerDetails.business_location) {
          this.providerDetails.business_location = {}
        }
        Vue.set(this.providerDetails.business_location, 'address2', address2)
      }
    },
    providerPayment() {
      return (
        this.providerDetails.billing?.payments_enabled &&
        this.providerDetails.billing?.currency
      )
    },
    providerRates: {
      get() {
        this.providerDetails.rates = this.providerDetails.rates || []
        return this.providerDetails.rates
      },
      set(rates) {
        Vue.set(this.providerDetails, 'rates', rates)
      }
    },
    providerHasRates() {
      return !!this.providerRates.find(x => x.rate_amount && x.rate_duration)
    },
    featureFlag() {
      const features = {}
      process.env.VUE_APP_FEATURE_FLAGS &&
        process.env.VUE_APP_FEATURE_FLAGS.split(' ').map(x => {
          features[x] = true
        })
      const feature_flags = Object.assign(
        this.providerDetails.features || {},
        features || {}
      )
      // console.log(feature_flags)
      return feature_flags
    },
    sampleRates() {
      // sample rates for placeholder text
      return [
        {
          rate: 'initial-consult',
          rate_name: 'Initial Consult',
          rate_amount: 100,
          rate_duration: 30
        },
        {
          rate: 'first-session',
          rate_name: 'First Session',
          rate_amount: 200,
          rate_duration: 60
        },
        {
          rate: 'regular-session',
          rate_name: 'Regular Session',
          rate_amount: 125,
          rate_duration: 30
        },
        {
          rate: 'regular-session-2',
          rate_name: 'Regular Session',
          rate_amount: 250,
          rate_duration: 60
        }
      ]
    }
  }
}
