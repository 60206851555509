import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const titleTemplate = 'Zenclear'

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // goes through the matched routes, finding the closest route with a title (eg: if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles, `/nested`'s will be chosen
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.name || r.title)

  // if a route with a title was found, set the document (page) title to that value
  if (nearestWithTitle)
    document.title =
      (nearestWithTitle.title || nearestWithTitle.name) + ' » ' + titleTemplate
  else document.title = titleTemplate

  next()
})

export default router
