<template>
  <side-bar>
    <template slot="links">
      <sidebar-item
        :link="{
          name: 'Dashboard',
          icon: 'ni ni-chart-bar-32',
          path: '/dashboard'
        }"
      />

      <sidebar-item
        :link="{ name: 'Clients', icon: 'fas fa-users', path: '/clients' }"
      />

      <sidebar-item
        :link="{
          name: 'Sessions',
          icon: 'fas fa-user-clock',
          path: '/sessions'
        }"
      />
      <sidebar-item
        :link="{
          name: 'Settings',
          icon: 'fas fa-cog',
          path: '/account/settings'
        }"
      />
      <!-- <sidebar-item
        :link="{
          name: 'Calendar',
          icon: 'ni ni-calendar-grid-58',
          path: '/timeline'
        }"
      /> -->
      <!-- <sidebar-item
        :link="{
          name: 'Payments',
          icon: 'far fa-credit-card',
          path: '/payments'
        }"
      /> -->
    </template>

    <template slot="links-after">
      <hr class="my-3" />
      <h6 class="navbar-heading p-0 text-muted">Helpful Links</h6>

      <b-nav class="navbar-nav mb-md-3">
        <b-nav-item to="/help" target="_blank">
          <i class="ni ni-support-16"></i>
          <b-nav-text class="p-0">Contact Us</b-nav-text>
        </b-nav-item>
      </b-nav>
    </template>
  </side-bar>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    }
  },
  mounted() {
    this.initScrollbar()
  }
}
</script>

<style></style>
