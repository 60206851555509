import omit from 'lodash.omit'
import apiClient from './apiClient'

export default {
  sanitizeFields(fields, endpoint) {
    const omitFields = [
      'provider',
      'deleted',
      '_id',
      '__v',
      'created',
      'updated',
      'mode',
      'row'
    ]
    if (endpoint) {
      omitFields.push(endpoint)
    }
    return omit(fields, omitFields)
  },
  async providerSync() {
    try {
      return (await apiClient.apiCall('get', '/provider/sync')).data
      // eslint-disable-next-line no-empty
    } catch (e) {}
  },
  async getProviderProfile() {
    return (await apiClient.apiCall('get', `/provider/profile`)).data
  },
  async editProviderProfile(data) {
    return await apiClient.apiCall('put', '/provider/profile', data)
  },
  async paymentSuccess(stripe_session) {
    return await apiClient.apiCall('put', `/payment/success/${stripe_session}`)
  },
  async chargeSession(session, data) {
    return await apiClient.apiCall('post', `/payment/charge/${session}`, data)
  },
  async listEndpoint(endpoint) {
    return (await apiClient.apiCall('get', `/${endpoint}`)).data
  },
  async getEndpoint(endpoint, id) {
    return (await apiClient.apiCall('get', `/${endpoint}/${id}`)).data
  },
  async addEndpoint(endpoint, data) {
    return await apiClient.apiCall('post', `/${endpoint}`, data)
  },
  async editEndpoint(endpoint, id, data) {
    return await apiClient.apiCall('put', `/${endpoint}/${id}`, data)
  },
  async deleteEndpoint(endpoint, id) {
    return await apiClient.apiCall('delete', `/${endpoint}/${id}`)
  },
  async getEvents() {
    return (await apiClient.apiCall('get', '/events')).data
  },
  async getEvent(id) {
    return (await apiClient.apiCall('get', '/events/' + id)).data
  },
  async getUsers2() {
    return (await apiClient.apiCall('get', '/users2')).data
  }
}
