import Vue from 'vue'
import apiService from '@/services/apiService'

const CLIENT_ENDPOINT = 'client'
const MIN_FETCH_INTERVAL = 5000

const clientsModule = {
  namespaced: true,
  state: () => ({
    loading: null,
    loadingDetails: null,
    ready: false,
    clientDetails: {},
    clients: []
  }),
  getters: {
    clientCollection(state) {
      return state.clients.sort(
        (x, y) =>
          (x.status || 'active').localeCompare(y.status || 'active') ||
          (x.first_name.toLowerCase() < y.first_name.toLowerCase() ? -1 : 1)
      )
    },
    activeClients(state) {
      return state.clients
        .filter(client => client.status === 'active')
        .sort((x, y) =>
          x.first_name.toLowerCase() < y.first_name.toLowerCase() ? -1 : 1
        )
    }
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setReady(state, ready) {
      state.ready = ready
    },
    setLoadingDetails(state, loadingDetails) {
      state.loadingDetails = loadingDetails
    },
    setClients(state, clients) {
      const clientDetails = {}
      clients.forEach(client => {
        clientDetails[client.client] = client
      })
      state.clients = clients
      state.clientDetails = clientDetails
    },
    addClient(state, client) {
      state.clients.push(client)
      Vue.set(state.clientDetails, client.client, client)
    },
    updateClient(state, { id, client }) {
      const clientIndex = state.clients.findIndex(s => s.client === id)
      Vue.set(state.clients, clientIndex, client)
      Vue.set(state.clientDetails, id, client)
    },
    deleteClient(state, id) {
      const clientIndex = state.clients.findIndex(s => s.client === id)
      Vue.delete(state.clients, clientIndex)
      Vue.delete(state.clientDetails, id)
    }
  },
  actions: {
    async fetch({ commit, state }) {
      if (!state.loading || Date.now() - state.loading > MIN_FETCH_INTERVAL) {
        commit('setLoading', Date.now())
        commit('setClients', await apiService.listEndpoint(CLIENT_ENDPOINT))
        commit('setReady', true)
      }
    },
    async fetchClientDetails({ commit, state }, id) {
      if (
        !state.loadingDetails ||
        Date.now() - state.loadingDetails > MIN_FETCH_INTERVAL
      ) {
        commit('setLoadingDetails', Date.now())
        const client = await apiService.getEndpoint(CLIENT_ENDPOINT, id)
        commit('updateClient', { id: client.client, client })
      }
    },
    async save({ commit, state }, clientToSave) {
      const found = state.clientDetails[clientToSave.client]
      let resp
      if (found) {
        // existing
        resp = await apiService.editEndpoint(
          CLIENT_ENDPOINT,
          found.client,
          apiService.sanitizeFields(clientToSave, CLIENT_ENDPOINT)
        )
        commit('updateClient', {
          id: clientToSave.client,
          client: { ...found, ...clientToSave }
        })
        return clientToSave.client
      } else {
        resp = await apiService.addEndpoint(
          CLIENT_ENDPOINT,
          apiService.sanitizeFields(clientToSave, CLIENT_ENDPOINT)
        )
        commit('addClient', {
          ...clientToSave,
          ...{ client: resp.data.client }
        })
        return resp.data.client
      }
    },
    async delete({ commit }, id) {
      await apiService.deleteEndpoint(CLIENT_ENDPOINT, id)
      commit('deleteClient', id)
    }
  }
}

export default clientsModule
