<template>
  <div class="wrapper">
    <notifications />
    <Sidebar />
    <div class="main-content">
      <DashboardNavbar :type="$route.meta.navbarType" />
      <Content @click="$sidebar.displaySidebar(false)" />
      <ContentFooter v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import Content from './Content.vue'
import Sidebar from './Sidebar.vue'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    Content,
    Sidebar
  }
}
</script>
<style></style>
